import { useState } from 'react'
import Header from '../components/Header'
import polygonIcon from '../images/send/polygonIcon.png'
import gnosisIcon from '../images/send/gnosisIcon.png'
import mainnetIcon from '../images/send/mainnetIcon.png'
import transformIcon from '../images/send/transformIcon.png'
import Tooltip from '../components/Tooltip'

const ChangeCoinBtn: React.FC = () => {
  const [formCoinListVisible, setFormCoinListVisible] = useState(false)
  const [formCoinList, setFormCoinList] = useState([
    { icon: polygonIcon, name: 'Polygon', checked: true },
    { icon: gnosisIcon, name: 'Gnosis', checked: false },
    { icon: mainnetIcon, name: 'Mainnet', checked: false },
  ])
  return (
    <div className="relative w-[126px] h-full">
      <button
        className={`bg-white rounded-[20px] w-[126px] h-full text-[#828282] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] border border-solid flex items-center px-[10px] text-[14px] justify-between ${
          formCoinListVisible ? 'border-[#1E94FC]' : 'border-white'
        }`}
        onClick={() => setFormCoinListVisible(!formCoinListVisible)}
      >
        {formCoinList.map(x =>
          x.checked ? (
            <>
              <div className="flex">
                <img src={x.icon} alt={x.icon} className="w-4 h-4 mr-2" />
                <span>{x.name}</span>
              </div>
              <svg
                className={`h-[14px] w-[14px] transition-all ${
                  formCoinListVisible
                    ? 'text-[#1E94FC] rotate-180'
                    : 'text-[#BDBDBD]'
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </>
          ) : null,
        )}
      </button>
      {/* Coin List */}
      <div
        className={`min-w-[150px] bg-white absolute top-[56px] left-0 z-30 rounded-2xl text-center overflow-hidden shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] transition-all ${
          formCoinListVisible ? 'max-h-[239px]' : 'max-h-0'
        }`}
      >
        <div className="pt-[22px] text-[#000] pb-[10px]">Select Network</div>
        <div className="max-h-[180px] overflow-hidden overflow-y-auto scrollbar-style">
          {formCoinList.map(x => (
            <div
              className="flex h-9 pl-[22px] hover:bg-[#F0F8FF] items-center text-[#333] cursor-pointer last:mb-[10px]"
              onClick={() => {
                setFormCoinList(
                  formCoinList.map(y =>
                    y.name === x.name
                      ? { ...y, checked: true }
                      : { ...y, checked: false },
                  ),
                )
                setFormCoinListVisible(false)
              }}
            >
              <img src={x.icon} alt={x.icon} className="w-4 h-4 mr-2" />
              {x.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Send: React.FC = () => {
  return (
    <div className="overflow-hidden font-regularText">
      <Header />
      <div className="mt-20 sm:mt-[57px] flex">
        <section className="w-[510px] mx-auto mt-[103px] mb-[237px] sm:px-4 sm:mt-[25px] sm:mb-[8px]">
          <div className="h-[52px] font-boldText text-[34px] leading-[52px] text-center">
            Send
          </div>
          {/* From Card */}
          <div className="h-[169px] bg-gradient-to-t from-[rgba(255,255,255,0.19)] to-[rgba(234,244,252,0.71)] mt-4 px-[30px] pt-[20px] rounded-2xl shadow-[16px_16px_32px_1px_rgba(0,0,0,0.04)] sm:px-4 sm:pt-4">
            <div className="flex items-center justify-between h-10">
              <div className="text-base leading-[19px] text-[#333]">
                <div className="flex items-center cursor-pointer group">
                  <span className="text-base leading-[19px] text-[#333] mr-[6px]">
                    From
                  </span>
                  <Tooltip
                    message="The final amount is subject to the actual execution due to market
      fluctuations and other reasons."
                  />
                </div>
              </div>
              <ChangeCoinBtn />
            </div>
            <div className="mt-[31px] flex items-end">
              <div className="font-numberText text-[42px] text-[#E0E0E0] leading-9">
                0.00
              </div>
              <div className="font-boldText text-base text-[#333] ml-[13px]">
                USDC
              </div>
            </div>
          </div>
          {/* Transform Icon */}
          <div className="w-[37px] h-[37px] rounded-[14px] mx-auto my-[18px]">
            <img src={transformIcon} alt="transformIcon" className="w-full" />
          </div>
          {/* TO Card */}
          <div className="h-[169px] bg-gradient-to-t from-[rgba(255,255,255,0.19)] to-[rgba(234,244,252,0.71)] mt-4 px-[30px] pt-[20px] rounded-2xl shadow-[16px_16px_32px_1px_rgba(0,0,0,0.04)] sm:px-4 sm:pt-4">
            <div className="flex items-center justify-between h-10">
              <div className="flex items-center cursor-pointer group">
                <span className="text-base leading-[19px] text-[#333] mr-[6px]">
                  To
                </span>
                <Tooltip
                  message="The final amount is subject to the actual execution due to market
      fluctuations and other reasons."
                />
              </div>
              <ChangeCoinBtn />
            </div>
            <div className="mt-[31px] flex items-end">
              <div className="font-numberText text-[42px] text-[#E0E0E0] leading-9">
                0.00
              </div>
              <div className="font-boldText text-base text-[#333] ml-[13px]">
                CTIO
              </div>
            </div>
          </div>
          {/* Tips Text */}
          <div className="flex mt-[31px] pl-[35px] pr-[31px] items-center justify-between sm:px-[18px] sm:mt-[23px]">
            <div className="font-numberText text-[#828282] text-[14px]">
              1 USD = 588235.2941 CTIO
            </div>
            <div className="flex items-center cursor-pointer group">
              <Tooltip
                message="The final amount is subject to the actual execution due to market
      fluctuations and other reasons."
              />
              <div className="mx-[6px]">Fees</div>
              <div>-</div>
            </div>
          </div>
          {/* Button */}
          <button className="w-full h-[52px] text-white bg-[#1E94FC] mt-[80px] rounded">
            Send
          </button>
        </section>
      </div>
    </div>
  )
}

export default Send
