import classnames from 'classnames'
import { FC } from 'react'

/**
 * @abstract: The parent element needs to use the "group" class name to locate the information pop-up window
 * @param {string} message: Tooltip Message
 * @param {string} className: class name
 * @returns
 */
const Tooltip: FC<{ message: string; className?: string }> = ({
  message,
  className,
}) => (
  <div className="relative ml-1">
    <svg
      className="h-[13px] w-[13px] text-[#828282] cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <div
      className={classnames(
        'absolute left-0 -top-[4px] -translate-y-full bg-white min-w-[180px] py-[22px] px-[17px] text-[#333] text-[12px] shadow-[0_0_24px_1px_rgba(0,0,0,0.1)] rounded hidden group-hover:block',
        className,
      )}
    >
      {message}
    </div>
  </div>
)

export default Tooltip
